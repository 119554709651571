import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { sendData } from '../actions/RmaActions'
import { logUserOut } from '../actions/AuthActions'
import Images from './Images'
import ProductListItem from './ProductListItem'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  order: state.rma.order,
  lang: state.auth.lang,
  reasons: state.config.reasons,
})

const mapDispatchToProps = dispatch => ({
  sendDataAction: (data) => dispatch(sendData(data)),
  logUserOutAction: () => dispatch(logUserOut()),
})

class OrderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.lines = []
    this.state = {
      valid: false,
      image_ids: [],
      note: '',
      show_bank: false,
      return_bank_account: '',
      return_address: '',
      validAcc: false
    }
  }

  noteChange = (event) => {
    this.setState({note: event.target.value})
  }

  handleChange = (event) => {
    const {target} = event
    let self = this
    this.setState({
      [target.name]: target.value
    }, () => {
      self.toggleConfirmButton()
    })
  }

  getProductListItem = (product, i) => (
    <ProductListItem key={product.id}
                     childIndex={i}
                     product={product}
                     parent={this}/>
  )

  logUserOut = (event) => {
    const {logUserOutAction} = this.props
    logUserOutAction()
  }

  collectData = () => {
    const {lines} = this
    const {order} = this.props
    const {
      note,
      image_ids,
      show_bank,
      return_bank_account,
      return_address,
    } = this.state
    let result = {
      order_id: order.id,
      lines: [],
      note
    }
    result.lines = lines.map(line => {
      const { checked, reason, note, agree } = line.state
      for (let key in checked){
        if(typeof note[key] === 'undefined'){
          note[key] = ''
        }
      }
      const { product } = line.props
      return {
        id: product.id,
        checked,
        reason,
        agree,
        note: note
      }
    })
    result.images = image_ids.map(id => { return [4, id, false] })
    if(show_bank){
      result.return_bank_account = return_bank_account
      result.return_address = return_address
    }
    return result
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { sendDataAction } = this.props
    sendDataAction(this.collectData())
  }

  validAcc = (nr) => {
    return /\d{10}/.test(nr.replaceAll(' ', ''))
  }

  toggleConfirmButton = () => {
    let state = {}
    const {
      valid,
      return_bank_account,
      return_address,
      validAcc,
      show_bank
    } = this.state
    const {lines} = this
    const {reasons} = this.props
    state.show_bank = false

    const validArr = lines.map(line => {
      let {checked, reason, agree} = line.state
      if (!Object.keys(checked).some(c => checked[c])){ return undefined } //line niezaznaczony
      let checked_indexes = Object.keys(checked).filter(i => (checked[i] === true))
      if (checked_indexes.some(i => ( //jeden z niezaznaczonych nie ma reason
          typeof reason[i] === 'undefined'
        ))){
          return false
      }

      let display_bank_account = false
      let confirmed = true
      Object.keys(checked).map(i => {
        if (checked[i] !== true){ return false }
        let r = reason[i]
        if (typeof r === 'undefined'){ return false }
        let a = agree[i]
        let rd = reasons.find(rd => {
          return rd.id === parseInt(r)
        })
        if (rd.display_bank_account === true){
          display_bank_account = true
        }
        if(rd.optional_note && rd.must_confirm){
          if(a !== true){ confirmed = false }
        }
      })
      if(display_bank_account){
        state.show_bank = true
      }
      if(!confirmed){ return false }
      return true
    })
    if(validArr.every(v => (v || typeof v === 'undefined')) && validArr.some(v => v)){
      state.valid = true
    } else {
      state.valid = false
    }
    state.validAcc = true
    if(state.show_bank){
      if(return_bank_account === '' || return_address === ''){
        state.valid = false
      }
      if(return_bank_account !== '') {
        state.validAcc = this.validAcc(return_bank_account)
      }
      let info = document.querySelector('.accInvalid')
      if(info){
        if(state.validAcc){
          info.classList.add('hidden')
        } else {
          info.classList.remove('hidden')
        }
      }
    }
    state.valid = state.valid && state.validAcc
    if(state.valid !== valid || state.show_bank !== show_bank || state.validAcc !== validAcc){
      this.setState(state)
    }
  }

  render() {
    const { authenticated } = this.props
    if(!authenticated) return (<Redirect to='/login' />)
    const { order } = this.props
    const { valid, note, show_bank, return_bank_account, return_address, validAcc } = this.state
    return (
      <div className="formularz">
        <div>
          {order &&
            <div className="rma_order ramka">
              <input type="hidden" name="order" value={order.id}/>
              <h4>
                {intl.get('Prosimy o wypełnienie poniższego formularza')}
              </h4>
              <div className="order_data row">
                <div className="col-lg-6">
                  <h5>{intl.get('Dane klienta')}</h5>
                  <div className="f_group">
                    <label>{intl.get('Nazwa')}:</label>
                    <div>{order.delivery_name}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Ulica')}:</label>
                    <div>{order.delivery_street}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Miasto')}:</label>
                    <div>{order.delivery_city}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Kod pocztowy')}:</label>
                    <div>{order.delivery_zip}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('E-mail')}:</label>
                    <div>{order.delivery_email}</div>
                  </div>
                  {order.allegro_client_login &&
                    <div className="f_group">
                      <label>{intl.get('Login')}:</label>
                      <div>{order.allegro_client_login}</div>
                    </div>
                  }
                  {order.vat &&
                    <div className="f_group">
                      <label>{intl.get('NIP')}:</label>
                      <div>{order.vat}</div>
                    </div>
                  }
                </div>
                <div className="col-lg-6">
                  <h5>{intl.get('Dane zamówienia')}</h5>
                  <div className="f_group">
                    <label>{intl.get('Nr zamówienia')}:</label>
                    <div>{order.name}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Data zamówienia')}:</label>
                    <div>{order.date_order}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Data wysyłki')}:</label>
                    <div>{order.delivery_date}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Metoda dostawy')}:</label>
                    <div>{order.carrier}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Wartość zamówienia')}:</label>
                    <div>{order.amount_total}</div>
                  </div>
                  <div className="f_group">
                    <label>{intl.get('Nr faktury VAT')}:</label>
                    <div>{order.invoice}</div>
                  </div>
                </div>
              </div>
              <br/>
              <h5>{intl.get('Produkty')}</h5>
              <div className="order_lines">
                {order.lines.map((product, i) => this.getProductListItem(product, i))}
              </div>
              <br/>
              <h5>{intl.get('Opis zgłoszenia')}</h5>
              <textarea className="note_area" value={note} name="note" onChange={this.handleChange}/>
              <br/>
              { show_bank &&
                <>
                <br/>
                <h5>{intl.get('Dane o rachunku bankowym')}<span className="redstar">*</span></h5>
                <input
                  placeholder={intl.get('Nr rachunku')}
                  type="text"
                  name="return_bank_account"
                  value={return_bank_account}
                  onChange={this.handleChange}
                  className={(validAcc?'':'invalid') + (return_bank_account===''?' empty':'')}/>
                <span className="redstar accInvalid hidden">{intl.get('Numer rachunku bankowego jest za krótki!')}</span>
                <textarea
                  placeholder={intl.get('Dane właściciela rachunku bankowego')}
                  className="note_area"
                  name="return_address"
                  value={return_address}
                  onChange={this.handleChange}/>
                <br/>
                </>
              }
              <span className="redstar">* - {intl.get('Pola wymagane')}</span>
              <br/>
              <br/>
              <h5>{intl.get('Dodaj zdjęcia')}</h5>
              <Images parent={this}/>
            </div>
          }
        </div>
        <div className="rma_header">
          <button className="pull-left secondary" onClick={this.logUserOut}>{intl.get('Anuluj')}</button>
          <button onClick={this.handleSubmit} className="pull-right" id="zatwierdz" disabled={!valid} >{intl.get('Dalej')}</button>
        </div>
      </div>
    )
  }
}
const Order = connect(mapStateToProps, mapDispatchToProps)(OrderComponent)
export default withRouter(Order)
