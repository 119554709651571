import { withRouter } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'

class HeaderComponent extends React.Component {

  render() {
    return(
      <header>
        <div className="logo"/>
      </header>
    )
  }
}
const Header = connect(null, null)(HeaderComponent)

export default withRouter(Header)
