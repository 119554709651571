import React from 'react'
import ImagesUploader from 'react-images-uploader';

class ImagesComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onLoadEnd = this.onLoadEnd.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
  }

  onLoadEnd(err, response) {
    if (response) {
      let {parent} = this.props
      parent.setState({
        image_ids: parent.state.image_ids.concat(response.map((url) => {
          let s = url.split('=')
          return parseInt(s[s.length - 1])
        }))
      })
    }
  }

  deleteImage(key) {
    this.props.parent.state.image_ids.splice(key, 1);
    this.setState({
      image_ids: this.props.parent.state.image_ids
    })
  }

  render() {
    const conf = window.ODOO
    const upload_url = conf.url + (conf.port?':'+conf.port:'')+'/project_upload_image'
    return(
      <div className="images">
        <ImagesUploader
          url={upload_url}
          inputId="ufile"
          optimisticPreviews
          onLoadEnd={this.onLoadEnd}
          deleteImage={this.deleteImage}
          />
      </div>
    )
  }
}

export default ImagesComponent;
