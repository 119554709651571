// Common
export const API_ERROR = 'API_ERROR'
export const SET_LANG = 'SET_LANG'
export const LOAD_TRANSLATIONS = 'LOAD_TRANSLATIONS'
export const SET_HIGH_CONTRAST = 'SET_HIGH_CONTRAST'

// Authentication
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const GET_CONFIG = 'GET_CONFIG'
export const HIDE_ERROR = 'HIDE_ERROR'

//Order
export const GET_ORDER = 'GET_ORDER'
export const SET_DONE = 'SET_DONE'
