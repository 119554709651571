import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { history } from '../store'
import Spinner from '../components/Spinner'
import App from '../components/App'
import LoginForm from '../components/LoginForm'
import Order from '../components/Order'
import Done from '../components/Done'

const mapStateToProps = state => ({
  authenticated: state.auth.session.token !== false,
  order: state.rma.order,
  done: state.rma.done,
})

class RoutesComponent extends React.Component {
  loadComponent = () => {
    const {
      authenticated,
      order,
      done
    } = this.props
    if(order && authenticated){
      if (done){
        return Done
      }
      return Order
    }
    return LoginForm
  }

  render() {
    const {
      authenticated
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <App>
          <Spinner/>
          <Route path="/login" component={LoginForm}/>
          <Route exact path="/"
                        authenticated={authenticated}
                        component={this.loadComponent()}/>
        </App>
      </ConnectedRouter>
    )
  }
}
const Routes = connect(mapStateToProps)(RoutesComponent)

export default Routes
