import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  reasons: state.config.reasons,
  lang: state.auth.lang
})

class ProductListItemComponent extends React.Component {
  constructor(props) {
    super(props)
    const { parent, childIndex } = props
    parent.lines[childIndex] = this
    this.state = {
      reason: {},
      checked: {},
      note: {},
      agree: {},
      expand: true
    }
  }

  reason_options = [
    /*['gwarancja', intl.get('Gwarancja – produkt uszkodzony')],
    ['rekojmia', intl.get('Rękojmia – produkt uszkodzony (nie przysługuje Przedsiębiorcom)')],
    ['niezgodnosc', intl.get('Interwencja – niezgodność w przesyłce')],
    ['uszkodzenie', intl.get('Interwencja – uszkodzenie w transporcie')],
    ['odstapienie', intl.get('Odstąpienie od umowy na odległość (bez podania przyczyny)')],*/
  ]

  toggleExpand = ()=>{
    const { expand } = this.state
    this.setState({
      expand: !expand
    })
  }

  checkboxClick = (e) => {
    const { checked, expand } = this.state
    const { product, parent } = this.props
    let new_checked
    const $checkbox = e.currentTarget
    const value = $checkbox.checked
    if(expand){
      const index = parseInt(e.currentTarget.dataset.index)
      new_checked = {
        ...checked,
        [index]: value
      }
    } else {
      new_checked = Array(product.quantity).fill(value)
    }
    this.setState({
      checked: new_checked,
    }, () => {
      parent.toggleConfirmButton()
    })
  }

  reasonChange = (e)=>{
    const { reason, expand } = this.state
    const { product, parent } = this.props
    let new_reason
    if(expand){
      const index = parseInt(e.currentTarget.dataset.index)
      new_reason = {
        ...reason,
        [index]: e.currentTarget.value
      }
    } else {
      new_reason = Array(product.quantity).fill(e.currentTarget.value)
    }
    this.setState({
      reason: new_reason
    }, () => {
      parent.toggleConfirmButton()
    })
  }

  agreeChange = (e)=>{
    const { agree, expand } = this.state
    const { product, parent } = this.props
    let new_agree
    if(expand){
      const index = parseInt(e.currentTarget.dataset.index)
      new_agree = {
        ...agree,
        [index]: e.currentTarget.checked
      }
    } else {
      new_agree = Array(product.quantity).fill(e.currentTarget.checked)
    }
    this.setState({
      agree: new_agree
    }, () => {
      parent.toggleConfirmButton()
    })
  }

  noteChange = (e)=>{
    const { note, expand } = this.state
    const { product, parent } = this.props
    let new_note
    if(expand){
      const index = parseInt(e.currentTarget.dataset.index)
      new_note = {
        ...note,
        [index]: e.currentTarget.value
      }
    } else {
      new_note = Array(product.quantity).fill(e.currentTarget.value)
    }
    this.setState({
      note: new_note
    }, () => {
      parent.toggleConfirmButton()
    })
  }

  renderItem(product, index){
    const { reason, checked, note, agree } = this.state
    const { reasons } = this.props
    let key = product.id
    let item_reason = reason[0] || ''
    let item_checked = checked[0] || false
    let item_note = note[0] || ''
    let item_agree = agree[0] || false
    if(typeof index !== 'undefined'){
      key = product.id+'.'+index
      item_reason = reason[index] || ''
      item_checked = checked[index] || false
      item_note = note[index] || ''
      item_agree = agree[index] || false
    }
    let reason_data = reasons.find(r => {
      return r.id === parseInt(item_reason)
    })
    return(
      <div key={key} className={item_checked?'prod_selected':''}>
        <div className="product_details">
          <div className="prod_info">
            <label className="product_check" htmlFor={'check_'+key}>
              {product.type != 'service' &&
                <>
                  <input type="checkbox"
                         id={'check_'+key}
                         data-index={index}
                         onChange={this.checkboxClick}
                         checked={item_checked}/>
                  <span className="checkmark"></span>
                </>
              }
              {product.default_code ? `[${product.default_code}] ` : ''}
              {product.name}
            </label>
            {(product.type != 'service') && item_checked &&
              <>
              { product.quantity > 1 &&
                <div className="f_group">
                  <label>{intl.get('Ilość')}:</label>
                  <div>{product.quantity}</div>
                </div>
              }
              <div className="f_group">
                <label>{intl.get('Kod')}:</label>
                <div>{product.barcode}</div>
              </div>
              <div className="f_group">
                <label>{intl.get('Cena')}:</label>
                <div>{product.price_unit}</div>
              </div>
              </>
            }
          </div>
          {(product.type != 'service') && item_checked &&
            <>
              <div className="reason">
                <label>{intl.get('Określ powód zgłoszenia')}:</label>
                <select onChange={this.reasonChange}
                        data-index={index}
                        value={item_reason}>
                  <option disabled value=''>-- {intl.get('Wybierz')} --</option>
                  {this.reason_options.map((opt, i)=>(
                      <option key={i}
                              value={opt.id}>
                        {opt.name}
                      </option>
                  ))}
                  {reasons.map((opt, i)=>(
                      <option key={i}
                              value={opt.id}>
                        {opt.name}
                      </option>
                  ))}
                </select>
              </div>
              <div className="note">
                <label>{intl.get('Notatka')}:</label>
                <textarea onChange={this.noteChange}
                        data-index={index}
                        value={item_note}>
                </textarea>
              </div>
              { reason_data && (typeof reason_data.optional_note !== 'undefined') &&
                <div className={`agree ${reason_data.must_confirm ? '' : 'no_checkbox'}`}>
                  <label htmlFor={'agree_'+key}>
                    { reason_data.must_confirm &&
                      <>
                        <input type="checkbox"
                               onChange={this.agreeChange}
                               data-index={index}
                               checked={item_agree}
                               id={'agree_'+key}/>
                        <span className="checkmark"></span>
                      </>
                    }
                    {reason_data.optional_note}
                    { reason_data.must_confirm &&
                        <span className="redstar">*</span>
                    }
                  </label>
                </div>
              }
            </>
          }
        </div>
      </div>
    )
  }

  render() {
    const { product } = this.props
    const { expand } = this.state
    const qty = product.quantity
    let className = `produkt ${expand ? 'expanded' : ''}`
    return (
      <div className={className}>
        { !expand &&
          this.renderItem(product)
        }
        { expand &&
          [...Array(qty).keys()].map((k) => {
            return this.renderItem(
              {
                ...product,
                quantity: 1,
              }, k
            )
          })
        }
      </div>
    )
  }
}

const ProductListItem = connect(mapStateToProps)(ProductListItemComponent)
export default ProductListItem
