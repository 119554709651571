import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { logUserIn, hideError } from '../actions/AuthActions'

const mapStateToProps = state => ({
  invalid: state.auth.invalid,
  authenticated: state.auth.session.token !== false,
  url_order: state.router.location.query.order || false,
  url_token: state.router.location.query.token || false,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
    logInAction: (order_name, token) => dispatch(
      logUserIn(order_name, token)
    ),
    hideErrorAction: () => dispatch(hideError())
})

class LoginFormComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      order_name: '',
      token: '',
    }
  }

  componentDidMount = () => {
    const {
      authenticated,
      url_order,
      url_token,
      logInAction
    } = this.props
    if(!authenticated && url_order && url_token){
      logInAction(url_order, url_token)
    }
  }

  handleChange = (event) => {
    const {hideErrorAction} = this.props
    const {target} = event
    this.setState({
      [target.name]: target.value
    })
    hideErrorAction()
  }

  handleSubmit = (event) => {
    const {order_name, token} = this.state
    const {logInAction} = this.props
    event.preventDefault()
    logInAction(order_name, token)
  }

  render() {
    const {
      authenticated,
      invalid
    } = this.props
    const {
      order_name,
      token
    } = this.state
    return (
      <div className="center_wrapper">
        { !authenticated ? (
          <div className="login_form">
            <form onSubmit={this.handleSubmit}>
              <h2>{intl.get('Panel zwrotów i reklamacji')}</h2>
              <div className="field">
                <input
                  placeholder=" "
                  name="order_name"
                  id="f-order_name"
                  type="text"
                  value={order_name}
                  onChange={this.handleChange} />
                <label htmlFor="f-order_name">{intl.get('Numer zamówienia')}</label>
              </div>
              <div className="field">
                <input
                  placeholder=" "
                  name="token"
                  id="f-token"
                  type="text"
                  value={token}
                  onChange={this.handleChange} />
                <label htmlFor="f-token">{intl.get('Token (wysyłany w wiadomości email z dokumentem zakupu)')}</label>
              </div>
              <input type="submit" value={intl.get('OK')} />
              { invalid &&
                <div className="login-failed">
                  { (!token && intl.get('Pole Token jest wymagane')) || intl.get('Nie znaleziono zamówienia')}
                </div>
              }
            </form>
          </div>
        ) : (
          <Redirect to='/' />
        )}
      </div>
    )
  }
}
const LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent)
export default withRouter(LoginForm)
