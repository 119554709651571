import { createAction } from 'redux-actions'
import { LOGIN_USER, LOGOUT_USER, SET_HIGH_CONTRAST, LOGIN_USER_ERROR, HIDE_ERROR, GET_CONFIG, API_ERROR} from '../constants/ActionTypes'
import { loadTranslations } from './RmaActions'
import Odoo from '../utils/Odoo'

export const logUserOut = createAction(LOGOUT_USER)

export function setHighContrast(high_contrast) {
  return dispatch => {
    dispatch({
      type: SET_HIGH_CONTRAST,
      payload: high_contrast
    })
  }
}

export function logUserIn(order_name, token) {
  return dispatch => {
    let params = {order_name, token}
    Odoo.login('form/login', params).then(session => {
      Odoo.call('form/get_config', {
        lang: session.data.lang === 'pl_PL' ? 'pl_PL' : 'en_US'
      }, session.token).then(config => {
        dispatch({
          type: GET_CONFIG,
          payload: { ...config }
        })
        dispatch({
          type: LOGIN_USER,
          payload: { ...session }
        })
        dispatch(loadTranslations((session.data.lang==='pl_PL') ? 'pl-PL' : 'en-US'))
      }).catch(error => {
        dispatch({
          type: API_ERROR,
          message: error.message
        })
      })
    }).catch(error => {
      dispatch({
        type: LOGIN_USER_ERROR,
        message: error.message
      })
    })
  }
}
export function hideError(){
  return dispatch => {
    dispatch({
      type: HIDE_ERROR
    })
  }
}
