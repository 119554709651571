import { GET_CONFIG, LOGOUT_USER } from '../constants/ActionTypes'

function setConfig(config){
  localStorage.setItem('ideaerp_config', JSON.stringify(config))
}

function clearConfig(){
  localStorage.removeItem('ideaerp_config')
}

function getConfig(){
  return JSON.parse(localStorage.getItem('ideaerp_config') || '{}')
}

export default function config(state = getConfig(), action) {
  switch (action.type) {
    case GET_CONFIG:
      setConfig(action.payload)
      return { ...state,
        ...action.payload
      }
    case LOGOUT_USER:
      clearConfig()
      return {}
    default:
      return state
  }
}
