import React from 'react'
import intl from 'react-intl-universal'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from './Header'
import Footer from './Footer'
import { isDark } from '../utils/Tools'
import { loadTranslations } from '../actions/RmaActions'

require('bootstrap/dist/css/bootstrap.min.css')
require('../styles/App.scss')
require('../styles/hc.scss')

const axios = require('axios')

const locales = [
  { name: 'English', value: 'en-US' },
  { name: 'Polski', value: 'pl-PL' }
]

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  initDone: state.auth.initDone,
  lang: state.router.location.query.lang
})

const mapDispatchToProps = dispatch => ({
  loadTranslationsAction: (lang) => dispatch(loadTranslations(lang))
})

class AppComponent extends React.Component {

  constructor() {
    super()
    const conf = window.ODOO
    if(document.querySelector('.logoStyle') === null){
      let logo = document.createElement('style')
      logo.classList.add('logoStyle')
      logo.innerHTML +=`
        #root header .logo{
          background-image: url(${conf.url + (conf.port?':'+conf.port:'')}/web/binary/company_logo)
        }
        `
      document.getElementsByTagName('head')[0].appendChild(logo)
    }
    if(document.querySelector('.brandStyle') === null){
      let brandUrl = conf.url + (conf.port?':'+conf.port:'')+'/brand/'
      axios.get(brandUrl).then((response) => {
        if(response.status === 200){
          let kolor = response.data
          let style = document.createElement('style')
          style.classList.add('brandStyle')
          let kolor_text = '#000'
          if(isDark(kolor)){
            kolor_text = '#fff'
          }
          if (kolor){
            style.innerHTML = `
              thead tr,
              #root main button:not(.react-datepicker__navigation):not(.filepond--file-action-button),
              #root main .button,
              #root main input[type="submit"],
              #popupContainer .mm-popup button,
              #root footer,
              #root thead tr,
              #popupContainer .mm-popup__box__header,
              #root footer a, #root footer a:hover, #root footer a:active, #root footer a:visited
              {
                background-color: ${kolor};
                color: ${kolor_text};
              }
              #root footer select.lang
              {
                color: ${kolor_text};
              }
              #root footer svg{
                fill: ${kolor_text} !important;
              }
              #root main .elem-list .elem-tile:hover{
                border-color: ${kolor};
              }`
          }
          document.getElementsByTagName('head')[0].appendChild(style)
        }
      }).catch(() => {
        // no brand data - do nothing
      })
    }
    let spinner = document.querySelector('.loaderWrap')
    if (spinner) spinner.style.display = 'none'
  }

  componentDidMount(){
    this.loadLocales()
  }

  componentDidUpdate(){
    const {lang} = this.props
    if (lang && intl.options.currentLocale !== lang){
      this.loadLocales()
    }
  }

  loadLocales() {
    const {lang, loadTranslationsAction} = this.props
    let currentLocale = lang || intl.determineLocale({
      localStorageLocaleKey: 'lang'
    })
    if (!locales.find((l)=>{return l.value === currentLocale })) {
      currentLocale = 'pl-PL'
    }

    loadTranslationsAction(currentLocale)
  }

  render() {
    const {authenticated, children, initDone} = this.props
    return (
      (initDone &&
      <article>
        <Header authenticated={authenticated}/>
        <main>
          {children}
        </main>
        <Footer locales={locales}/>
      </article>)
      || null
    )
  }
}
const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent)

export default withRouter(App)
