import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { LOGIN_USER, SET_DONE, LOGOUT_USER, API_ERROR } from '../constants/ActionTypes'

const initialState = {
  order: false,
  done: false
}

export default function rma(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      let res = { ...state,
        order: action.payload.data
      }
      return res
    case LOGOUT_USER:
      return initialState
    case SET_DONE:
      return {
        ...state,
        done: action.payload
      }
    case API_ERROR:
      Popup.alert(action.message || intl.get('Wystąpił nieznany błąd'))
      return { ...state }
    default:
      return state
  }
}
