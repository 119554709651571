import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { logUserOut } from '../actions/AuthActions'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  done: state.rma.done,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
  logUserOutAction: () => dispatch(logUserOut())
})

class DoneComponent extends React.Component {
  confirmDone = () => {
    const {
      logUserOutAction
    } = this.props
    logUserOutAction()
  }

  render() {
    const { authenticated, done } = this.props
    if(!authenticated) return (<Redirect to='/login' />)
    return (
      <div className="container">
        <div className="ramka done">
          <h2>
            {intl.get('Zgłoszenie przyjęte')}
          </h2>
          <div className="subheader">
            {intl.get('Numer zgłoszenia')}
            &nbsp;
            <strong>{done},</strong>
            &nbsp;
            {intl.get('potwierdzenie przyjęcia zgłoszenia zostało wysłane na adres mailowy podany w zamówieniu.')}
          </div>
          <button onClick={this.confirmDone}>OK</button>
        </div>
      </div>
    )
  }
}

const Done = connect(mapStateToProps, mapDispatchToProps)(DoneComponent)
export default withRouter(Done)
